
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useRouterStore = defineStore('router', () => {

  const ultimaRotaVisitada = ref(null);
  const rotaOrigem = ref(null);
  const rotaAtual = ref(null);
  const menuAtivo = ref(true);

  const definirRotaAtual = (rota) => {
    rotaAtual.value = rota;
  };

  const definirMenuAtivo = (valor) => {
    menuAtivo.value = valor;
  }

  const definirUltimaRotaVisitada = (rota) => {
    ultimaRotaVisitada.value = rota;
  };

  const definirRotaOrigem = (rota) => {
    rotaOrigem.value = rota;
  };

  return {
    ultimaRotaVisitada,
    definirUltimaRotaVisitada,
    rotaOrigem,
    definirRotaOrigem,
    rotaAtual,
    definirRotaAtual,
    menuAtivo,
    definirMenuAtivo,
  }

}, { persist: true });
