<script setup>
import {
    status_pagamento_pendente,
    status_pagamento_autorizado,
    status_pagamento_emprocessamento,
    status_pagamento_naoautorizado,
    status_pagamento_rejeitadocomerro,
    status_pagamento_cancelado,
    status_pagamento_confirmado,
    getStatusPagamentoSeveridade,
} from '@/commons/projetos';


const props = defineProps({
    valor: String,
})

</script>

<template>
    <Tag :value="props.valor" :pt="{
        root: { class: getStatusPagamentoSeveridade(props.valor) }
    }">

        <template #icon>
            <i v-if="props.valor == status_pagamento_pendente" class="pi pi-eye text-xs mr-2" />
            <i v-if="props.valor == status_pagamento_autorizado" class="pi pi-lock-open text-xs mr-2" />
            <i v-if="props.valor == status_pagamento_naoautorizado" class="pi pi-ban text-xs mr-2" />
            <i v-if="props.valor == status_pagamento_confirmado" class="pi pi-check-circle text-xs mr-2" />
            <i v-if="props.valor == status_pagamento_cancelado" class="pi pi-times-circle text-xs mr-2" />
            <i v-if="props.valor == status_pagamento_emprocessamento" class="pi pi-hourglass text-xs mr-2" />
            <i v-if="props.valor == status_pagamento_rejeitadocomerro" class="pi pi-exclamation-triangle text-xs mr-2" />
        </template>
    </Tag>
</template>