<script setup>
import {
    status_solicitacao_pendente,
    status_solicitacao_concluida,
    status_solicitacao_reprovada,
    status_solicitacao_cancelada,
    status_solicitacao_aprovada,
    status_solicitacao_rascunho,
    status_solicitacao_contratacao,
    status_solicitacao_autorizacao,
    status_solicitacao_ajuste,
    status_solicitacao_naoautorizada, 
    status_solicitacao_fornecimento,
    status_solicitacao_pagamento,
    getStatusSolicitacaoProjetoSeveridade,
} from '@/commons/projetos';


const props = defineProps({
    valor: String,
})

</script>

<template>
    <Tag :value="props.valor" :pt="{
        root: { class: getStatusSolicitacaoProjetoSeveridade(props.valor) }
    }">

        <template #icon>
            <i v-if="props.valor == status_solicitacao_aprovada || props.valor == status_solicitacao_concluida" class="pi pi-check-circle text-xs mr-2" />
            <i v-if="props.valor == status_solicitacao_reprovada || props.valor == status_solicitacao_naoautorizada"
                class="pi pi-ban text-xs mr-2" />
            <i v-if="props.valor == status_solicitacao_rascunho" class="pi pi-file-edit text-xs mr-2" />
            <i v-if="props.valor == status_solicitacao_ajuste" class="pi pi-file-edit text-xs mr-2" />
            <i v-if="props.valor == status_solicitacao_pendente" class="pi pi-eye text-xs mr-2" />
            <i v-if="props.valor == status_solicitacao_contratacao" class="pi pi-hourglass text-xs mr-2" />
            <i v-if="props.valor == status_solicitacao_autorizacao" class="pi pi-hourglass text-xs mr-2" />
            <i v-if="props.valor == status_solicitacao_fornecimento" class="pi pi-hourglass text-xs mr-2" />
            <i v-if="props.valor == status_solicitacao_pagamento" class="pi pi-hourglass text-xs mr-2" />
            <i v-if="props.valor == status_solicitacao_cancelada" class="pi pi-times-circle text-xs mr-2" />
        </template>
    </Tag>
</template>