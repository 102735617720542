<script setup>
import { onMounted, ref } from 'vue';
import {
    formataDataHoraFromDb,
} from '@/commons/utils';

const props = defineProps({
    valor: Array
})

const valoresTabela = ref([])
const temPagamentoId = ref(false)

onMounted(() => {
    valoresTabela.value = props.valor;
    temPagamentoId.value = valoresTabela.value.some(item => item.pagamento_id !== undefined);
})
</script>

<template>
    <DataTable :value="valoresTabela" class="w-full" :pt="{
        thead: { class: 'hidden lg-table-header-group' },
        header: { class: 'border-none px-0' },
        wrapper: { class: 'border-round-2xl border-1 border-200', style: 'background:none !important;' },
        bodyrow: { style: 'background:none !important;' },
        table: { class: 'mt-1' },
        emptyMessageCell: {
            class: 'border-none surface-ground text-900'
        }, paginator: {
            root: {
                style: 'background: none !important;',
            }
        },
    }">
        <Column class="lg:hidden w-auto">
            <template #body="slotProps">
                <span class="font-semibold text-900">{{ slotProps.data.tipo_solicitacao_projeto_status || slotProps.data.tipo_pagamento_status || slotProps.data.tipo_contratacao_status }}</span>
                <div class="flex flex-column mt-1">
                    <span class="text-sm">Por {{ slotProps.data.pessoa_fisica_nome }}</span>
                    <span class="text-sm">Em {{ formataDataHoraFromDb(slotProps.data.data_criacao) }}</span>
                </div>
                <div class="text-sm mt-4" v-if="!temPagamentoId">
                    <p class="font-semibold mb-1">Justificativa:</p>
                    <p class="m-0">
                        {{ slotProps.data.justificativa }}
                    </p>
                </div>
            </template>
        </Column>

        <Column header="Status" class="hidden lg-table-cell" >
            <template #body="slotProps">
                <span class="text-sm lg:text-base font-semibold text-900">{{ slotProps.data.tipo_solicitacao_projeto_status || slotProps.data.tipo_pagamento_status  || slotProps.data.tipo_contratacao_status}}</span>
                <div class="flex flex-column mt-1">
                    <span class="text-xs lg:text-sm">Por {{ slotProps.data.pessoa_fisica_nome }}</span>
                    <span class="text-xs lg:text-sm">Em {{ formataDataHoraFromDb(slotProps.data.data_criacao || slotProps.data.data_status) }}</span>
                </div>
            </template>
        </Column>

        <Column v-if="!temPagamentoId">
            <template #header>
                <span class="text-sm lg:text-base font-semibold text-900">Justificativa</span>
            </template>
            <template #body="slotProps">
                <p class="m-0">
                    {{ slotProps.data.justificativa }}
                </p>
            </template>
        </Column>
    </DataTable>
</template>