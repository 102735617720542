<script setup>
import { useRouterStore } from '@/stores/routerStore';


import router from '@/router';

const props = defineProps({
    label: String,
    action: String,
    icon: Boolean,
    type: String
})

const routerStore = useRouterStore();

const voltarRota = async () => {
    const history = window.history;

    if (props.action == 'historicoAnterior') {
        window.addEventListener('popstate', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, { once: true });
        history.back();
    }

    if (props.action == 'rotaAnterior') {
        router.push({ name: routerStore.ultimaRotaVisitada.name, params: { id: routerStore.ultimaRotaVisitada.params.id } });
    }
    if (props.action == 'rotaOrigem') {
        router.push({ name: routerStore.rotaOrigem.name });
    }

    window.addEventListener('scroll', function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, { once: true });
}
</script>

<template>
    <div :class="['flex flex-row gap-2 align-items-center font-medium text-500 cursor-pointer w-max hover:text-600 select-none text-sm', { 'mb-5': type !== 'cancel' }]"
        @click="voltarRota()">
        <span class="pi pi-arrow-left" v-if="icon == true"></span>

        <p v-if="type == 'cancel'">Cancelar</p>

        <p v-else>
            Voltar para
            <template v-if="props.action == 'historicoAnterior'">
                <span>a página anterior</span>
            </template>

            <template v-if="props.action == 'rotaAnterior'">
                <span v-if="routerStore.ultimaRotaVisitada.name === 'detalheProjeto'">os detalhes do projeto</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'detalheSolicitacao'">os detalhes da solicitação</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'listaProjetos'">a lista de projetos</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'listaInstrumentosJuridicos'">a lista de instrumentos jurídicos</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'home'">a página inicial</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'listaPagamentos'">a lista de pagamentos</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'meusProjetos'">seus projetos</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'perfil'">o detalhe de perfil</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'detalheContratacao'">os detalhes do contratação</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'listaFornecedores'">a lista de fornecedores</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'listaSolicitacaoCompras'">a lista de solicitações</span>
                <span  v-if="routerStore.ultimaRotaVisitada.name === 'listaPessoaFisica'">a lista de pessoas físicas</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'listaPessoasJuridicas'">a lista de pessoas jurídicas</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'pagamentos'">a lista de pagamentos</span>
                <span v-if="routerStore.ultimaRotaVisitada.name === 'membros'">a lista de membros</span>
            </template>

            <template v-if="props.action == 'rotaOrigem'">
                <span v-if="routerStore.rotaOrigem.name === 'home'">a página inicial</span>
                <span v-if="routerStore.rotaOrigem.name === 'meusProjetos'">seus projetos</span>
                <span v-if="routerStore.rotaOrigem.name === 'listaInstrumentosJuridicos'"> a lista de instrumentos jurídicos</span>
                <span v-if="routerStore.rotaOrigem.name === 'listaProjetos'">a lista de projetos</span>
                <span v-if="routerStore.rotaOrigem.name === 'listaContratacoes'">a lista de compras em contratação</span>
            </template>
        </p>
    </div>

</template>