

export const status_contratacao_iniciada = import.meta.env.VITE_STATUS_CONTRATACAO_INICIADA;
export const status_contratacao_apuracao = import.meta.env.VITE_STATUS_CONTRATACAO_APURACAO;
export const status_contratacao_autorizacao = import.meta.env.VITE_STATUS_CONTRATACAO_AUTORIZACAO;
export const status_contratacao_autorizada = import.meta.env.VITE_STATUS_CONTRATACAO_AUTORIZADA;
export const status_contratacao_naoautorizada = import.meta.env.VITE_STATUS_CONTRATACAO_NAO_AUTORIZADA;
export const status_contratacao_fornecimento = import.meta.env.VITE_STATUS_CONTRATACAO_FORNECIMENTO;
export const status_contratacao_pagamento = import.meta.env.VITE_STATUS_CONTRATACAO_PAGAMENTO;
export const status_contratacao_concluida = import.meta.env.VITE_STATUS_CONTRATACAO_CONCLUIDA;

// import {
//     status_contratacao_iniciada,
//     status_contratacao_apuracao,
//     status_contratacao_autorizacao,
//     status_contratacao_autorizada,
//     status_contratacao_naoautorizada,
//     status_contratacao_fornecimento,
//     status_contratacao_pagamento,
//     status_contratacao_concluida,
// } from '@/commons/projetos';


export const status_solicitacao_pendente = import.meta.env.VITE_STATUS_SOLICITACAO_PENDENTE;
export const status_solicitacao_aprovada = import.meta.env.VITE_STATUS_SOLICITACAO_APROVADA;
export const status_solicitacao_ajuste = import.meta.env.VITE_STATUS_SOLICITACAO_AJUSTE;
export const status_solicitacao_contratacao = import.meta.env.VITE_STATUS_SOLICITACAO_CONTRATACAO;
export const status_solicitacao_reprovada = import.meta.env.VITE_STATUS_SOLICITACAO_REPROVADA;
export const status_solicitacao_cancelada = import.meta.env.VITE_STATUS_SOLICITACAO_CANCELADA;
export const status_solicitacao_rascunho = import.meta.env.VITE_STATUS_SOLICITACAO_RASCUNHO;
export const status_solicitacao_pagamento = import.meta.env.VITE_STATUS_SOLICITACAO_PAGAMENTO;
export const status_solicitacao_autorizacao = import.meta.env.VITE_STATUS_SOLICITACAO_AUTORIZACAO;
export const status_solicitacao_concluida = import.meta.env.VITE_STATUS_SOLICITACAO_CONCLUIDA;
export const status_solicitacao_fornecimento = import.meta.env.VITE_STATUS_SOLICITACAO_FORNECIMENTO;
export const status_solicitacao_naoautorizada = import.meta.env.VITE_STATUS_SOLICITACAO_NAO_AUTORIZADA;

// import {
//     status_solicitacao_pendente,
//     status_solicitacao_aprovada,
//     status_solicitacao_ajuste,
//     status_solicitacao_contratacao,
//     status_solicitacao_reprovada,
//     status_solicitacao_cancelada,
//     status_solicitacao_rascunho,
//     status_solicitacao_pagamento,
//     status_solicitacao_autorizacao,
//     status_solicitacao_concluida,
//     status_solicitacao_fornecimento,
//     status_solicitacao_naoautorizada,
// } from '@/commons/projetos';

export const status_solicitacao_relatorio_em_analise = import.meta.env.VITE_STATUS_SOLICITACAO_RELATORIO_EM_ANALISE;
export const status_solicitacao_relatorio_pendente = import.meta.env.VITE_STATUS_SOLICITACAO_RELATORIO_PENDENTE;
export const status_solicitacao_relatorio_aprovado = import.meta.env.VITE_STATUS_SOLICITACAO_RELATORIO_APROVADO;
export const status_solicitacao_relatorio_ajuste = import.meta.env.VITE_STATUS_SOLICITACAO_RELATORIO_AJUSTE;

// import {
//    status_solicitacao_relatorio_em_analise,
//     status_solicitacao_relatorio_pendente,
//     status_solicitacao_relatorio_aprovado,
//     status_solicitacao_relatorio_ajuste,
// } from '@/commons/projetos';

export const tipo_solicitacao_auxilio_financeiro_pessoa_fisica = import.meta.env.VITE_TIPO_SOLICITACAO_AUXILIO_FINANCEIRO_PESSOA_FISICA;
export const tipo_solicitacao_diaria = import.meta.env.VITE_TIPO_SOLICITACAO_DIARIA;
export const tipo_solicitacao_hospedagem = import.meta.env.VITE_TIPO_SOLICITACAO_HOSPEDAGEM;
export const tipo_solicitacao_material_consumo = import.meta.env.VITE_TIPO_SOLICITACAO_MATERIAL_CONSUMO;
export const tipo_solicitacao_material_equipamento = import.meta.env.VITE_TIPO_SOLICITACAO_MATERIAL_EQUIPAMENTO;
export const tipo_solicitacao_pagamento_bolsa = import.meta.env.VITE_TIPO_SOLICITACAO_PAGAMENTO_BOLSA;
export const tipo_solicitacao_pagamento_servico = import.meta.env.VITE_TIPO_SOLICITACAO_PAGAMENTO_SERVICO;
export const tipo_solicitacao_pagamento_material_consumo = import.meta.env.VITE_TIPO_SOLICITACAO_PAGAMENTO_MATERIAL_CONSUMO;
export const tipo_solicitacao_servico_pessoa_juridica = import.meta.env.VITE_TIPO_SOLICITACAO_SERVICO_PESSOA_JURIDICA;
export const tipo_solicitacao_servico_pessoa_fisica = import.meta.env.VITE_TIPO_SOLICITACAO_SERVICO_PESSOA_FISICA;

// import {
//     tipo_solicitacao_auxilio_financeiro_pessoa_fisica,
//     tipo_solicitacao_diaria,
//     tipo_solicitacao_hospedagem,
//     tipo_solicitacao_material_consumo,
//     tipo_solicitacao_material_equipamento
//     tipo_solicitacao_pagamento_bolsa,
//     tipo_solicitacao_pagamento_servico,
//     tipo_solicitacao_servico_pessoa_juridica,
//     tipo_solicitacao_servico_pessoa_fisica,
//     tipo_solicitacao_pagamento_material_consumo,
// } from '@/commons/projetos';



export const rubricaMaterialConsumo = import.meta.env.VITE_CODIGO_RUBRICA_MATERIAL_CONSUMO;
export const rubricaMaterialPermanente = import.meta.env.VITE_CODIGO_RUBRICA_MATERIAL_PERMANENTE;
export const rubricaDiaria = import.meta.env.VITE_CODIGO_RUBRICA_DIARIAS;
export const rubricaAuxilioFinanceiro = import.meta.env.VITE_CODIGO_RUBRICA_AUXILIO_FINANCEIRO;
export const rubricaBolsaPesquisador = import.meta.env.VITE_CODIGO_RUBRICA_BOLSA_PESQUISADOR;
export const rubricaBolsaAluno = import.meta.env.VITE_CODIGO_RUBRICA_BOLSA_ALUNO;
export const rubricaServicoPF = import.meta.env.VITE_CODIGO_RUBRICA_SERVICO_PF;
export const rubricaServicoPJ = import.meta.env.VITE_CODIGO_RUBRICA_SERVICO_PJ;
export const rubricaPassagem = import.meta.env.VITE_CODIGO_RUBRICA_PASSAGEM;

// import {
//     rubricaMaterialConsumo,
//     rubricaMaterialPermanente,
//     rubricaDiaria,
//     rubricaAuxilioFinanceiro,
//     rubricaBolsaPesquisador,
//     rubricaBolsaAluno,
//     rubricaServicoPF,
//     rubricaServicoPJ,
//     rubricaPassagem,
// } from '@/commons/projetos';

export const status_pagamento_pendente = import.meta.env.VITE_STATUS_PAGAMENTO_PENDENTE;
export const status_pagamento_autorizado = import.meta.env.VITE_STATUS_PAGAMENTO_AUTORIZADO;
export const status_pagamento_confirmado = import.meta.env.VITE_STATUS_PAGAMENTO_CONFIRMADO;
export const status_pagamento_rejeitadocomerro = import.meta.env.VITE_STATUS_PAGAMENTO_REJEITADO_COM_ERRO;
export const status_pagamento_naoautorizado = import.meta.env.VITE_STATUS_PAGAMENTO_NAO_AUTORIZADO;
export const status_pagamento_emprocessamento = import.meta.env.VITE_STATUS_PAGAMENTO_EM_PROCESSAMENTO;
export const status_pagamento_cancelado = import.meta.env.VITE_STATUS_PAGAMENTO_CANCELADO;

// import {
//     status_pagamento_pendente,
//     status_pagamento_autorizado,
//     status_pagamento_confirmado,
//     status_pagamento_rejeitadocomerro,
//     status_pagamento_naoautorizado,
//     status_pagamento_emprocessamento,
// } from '@/commons/projetos';

export const membro_tipo_aluno = import.meta.env.VITE_MEMBRO_TIPO_ALUNO;
export const membro_tipo_pesquisador = import.meta.env.VITE_MEMBRO_TIPO_PESQUISADOR;
export const membro_tipo_coordenador = import.meta.env.VITE_MEMBRO_TIPO_COORDENADOR;
export const membro_tipo_servidor = import.meta.env.VITE_MEMBRO_TIPO_SERVIDOR;
export const membro_tipo_consultor = import.meta.env.VITE_MEMBRO_TIPO_CONSULTOR;
export const membro_tipo_bolsista_servidor = import.meta.env.VITE_MEMBRO_TIPO_BOLSISTA_SERVIDOR;
export const membro_tipo_bolsista_consultor = import.meta.env.VITE_MEMBRO_TIPO_BOLSISTA_CONSULTOR;
export const membro_tipo_bolsista_aluno = import.meta.env.VITE_MEMBRO_TIPO_BOLSISTA_ALUNO;

// import {
//     membro_tipo_aluno,
//     membro_tipo_pesquisador,
//     membro_tipo_coordenador,
//     membro_tipo_servidor,
//     membro_tipo_consultor,
//     membro_tipo_bolsista_servidor,
//     membro_tipo_bolsista_consultor,
//     membro_tipo_bolsista_aluno,
// } from '@/commons/projetos';


export const status_projeto_rascunho = import.meta.env.VITE_STATUS_PROJETO_RASCUNHO;
export const status_projeto_emexecucao = import.meta.env.VITE_STATUS_PROJETO_EM_EXECUCAO;
export const status_projeto_inativo = import.meta.env.VITE_STATUS_PROJETO_INATIVO;
export const status_projeto_cancelado = import.meta.env.VITE_STATUS_PROJETO_CANCELADO;
export const status_projeto_concluido = import.meta.env.VITE_STATUS_PROJETO_CONCLUIDO;
export const status_projeto_ativo = import.meta.env.VITE_STATUS_PROJETO_ATIVO

// import {
//     status_projeto_rascunho,
//     status_projeto_emexecucao,
//     status_projeto_inativo,
//     status_projeto_cancelado,
//     status_projeto_concluido,
//     status_projeto_ativo
// } from '@/commons/projetos';


export function getStatusSolicitacaoProjetoSeveridade(status) {
    if (status == status_solicitacao_pendente) {
        return 'border-round-md text-purple-700 font-medium bg-purple-100'
    } else if (status == status_solicitacao_aprovada || status == status_solicitacao_concluida) {
        return 'border-round-md text-green-700 font-medium bg-green-100';
    } else if (status == status_solicitacao_ajuste) {
        return 'border-round-md text-primary-600 font-medium bg-primary-100';
    } else if (status == status_solicitacao_reprovada || status == status_solicitacao_cancelada || status == status_solicitacao_naoautorizada) {
        return 'border-round-md text-red-700 font-medium bg-red-100';
    } else if (status == status_solicitacao_rascunho) {
        return 'border-round-md text-bluegray-700 font-medium bg-bluegray-100';
    } else if (status == status_solicitacao_contratacao || status == status_solicitacao_autorizacao || status == status_solicitacao_fornecimento || status == status_solicitacao_pagamento) {
        return 'border-round-md text-yellow-700 font-medium bg-yellow-100';
    } else {
        return 'border-round-md text-bluegray-700 font-medium bg-bluegray-100';
    }
};

export function getStatusRelatorioDiariaSeveridade(status) {
    if (status == status_solicitacao_relatorio_pendente) {
        return 'border-round-xl text-red-600 font-medium border-red-500 border-1 bg-red-50';
    } else if (status == status_solicitacao_relatorio_aprovado) {
        return 'border-round-xl text-green-600 font-medium border-green-500 border-1 bg-green-50';
    } else if (status == status_solicitacao_relatorio_ajuste) {
        return 'border-round-xl text-primary-600 font-medium border-primary-500 border-1 bg-primary-50';
    } else if (status == status_solicitacao_relatorio_em_analise) {
        return 'border-round-md text-yellow-600 font-semibold border-yellow-500 border-1 bg-yellow-50';
    } else {
        return 'border-round-xl text-bluegray-600 font-medium border-bluegray-500 border-1 bg-bluegray-50';
    }
};

export function getStatusPagamentoSeveridade(status) {
    if (status == status_pagamento_pendente) {
        return 'border-round-md text-purple-700 font-medium bg-purple-100';
    } else if (status == status_pagamento_autorizado) {
        return 'border-round-xl text-cyan-700 font-medium bg-cyan-100';
    } else if (status == status_pagamento_confirmado) {
        return 'border-round-md text-green-700 font-medium bg-green-100';
    } else if (status == status_pagamento_rejeitadocomerro) {
        return 'border-round-md text-pink-700 font-medium bg-pink-100';
    } else if (status == status_pagamento_naoautorizado) {
        return 'border-round-md text-red-700 font-medium bg-red-100';
    } else if (status == status_pagamento_emprocessamento) {
        return 'border-round-md text-yellow-700 font-medium bg-yellow-100';
    } else if (status == status_pagamento_cancelado) {
        return 'border-round-md text-bluegray-700 font-medium bg-bluegray-100';
    } else {
        return 'border-round-xl text-bluegray-600 font-medium bg-bluegray-50';
    }
}

export function getStatusProjetoSeveridade(status) {
    if (status == status_projeto_rascunho) {
        return 'border-round-md text-yellow-600 font-semibold border-yellow-500 border-1 bg-yellow-50 uppercase text-xs';
    } else if (status == status_projeto_emexecucao) {
        return 'border-round-md text-green-600 font-semibold border-green-500 border-1 bg-green-50 uppercase text-xs';
    } else if (status == status_projeto_inativo) {
        return 'border-round-md text-bluegray-600 font-semibold border-bluegray-500 border-1 bg-bluegray-50 uppercase text-xs ';
    } else if (status == status_projeto_cancelado) {
        return 'border-round-md text-red-600 font-semibold border-red-500 border-1 bg-red-50 uppercase text-xs';
    } else if (status == status_projeto_concluido) {
        return 'border-round-md text-cyan-600 font-semibold border-cyan-500 border-1 bg-cyan-50 uppercase text-xs';
    } else {
        return 'border-round-md text-bluegray-600 font-semibold border-bluegray-500 border-1 bg-bluegray-50 uppercase text-xs';
    }
};

export function getStatusContratacaoSeveridade(status) {
    if (status == status_contratacao_iniciada) {
        return 'border-round-md text-yellow-600 font-semibold border-yellow-500 border-1 bg-yellow-50 uppercase';
    } else if (status == status_contratacao_apuracao) {
        return 'border-round-md text-purple-600 font-semibold border-purple-500 border-1 bg-purple-50 uppercase';
    } else if (status == status_contratacao_autorizada) {
        return 'border-round-md text-indigo-600 font-semibold border-indigo-500 border-1 bg-indigo-50 uppercase';
    } else if (status == status_contratacao_autorizacao) {
        return 'border-round-md text-cyan-600 font-semibold border-cyan-500 border-1 bg-cyan-50 uppercase';
    } else if (status == status_contratacao_naoautorizada) {
        return 'border-round-md text-red-600 font-semibold border-red-500 border-1 bg-red-50 uppercase';
    } else if (status == status_contratacao_fornecimento) {
        return 'border-round-md text-bluegray-600 font-semibold border-bluegray-500 border-1 bg-bluegray-50 uppercase';
    } else if (status == status_contratacao_pagamento) {
        return 'border-round-md text-orange-600 font-semibold border-orange-500 border-1 bg-orange-50 uppercase';
    } else if (status == status_contratacao_concluida) {
        return 'border-round-md text-green-600 font-semibold border-green-500 border-1 bg-green-50 uppercase';
    } else {
        return 'border-round-md text-bluegray-600 font-semibold border-bluegray-500 border-1 bg-bluegray-50 uppercase';
    }
};

//     status_contratacao_iniciada,
//     status_contratacao_apuracao,
//     status_contratacao_autorizacao,
//     status_contratacao_autorizada,
//     status_contratacao_naoautorizada,
//     status_contratacao_fornecimento,
//     status_contratacao_pagamento,
//     status_contratacao_concluida,
