/* eslint-disable vue/no-reserved-component-names */
/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue'
import { createPinia } from 'pinia';
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import * as Sentry from "@sentry/vue";

import App from '@/App.vue'
import PrimeVue from 'primevue/config';

// Componentes do PrimeVue
import Skeleton from 'primevue/skeleton';
import PanelMenu from 'primevue/panelmenu';
import ConfirmPopup from 'primevue/confirmpopup';
import Fieldset from 'primevue/fieldset';
import VueTheMask from 'vue-the-mask'
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import ripple from 'primevue/ripple';
import styleclass from 'primevue/styleclass';
import Badge from 'primevue/badge';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import FileUpload from 'primevue/fileupload';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Calendar from 'primevue/calendar';
import Chip from 'primevue/chip';
import InputNumber from 'primevue/inputnumber';
import OverlayPanel from 'primevue/overlaypanel';
import Message from 'primevue/message';
import ConfirmDialog from 'primevue/confirmdialog';
import Tooltip from 'primevue/tooltip';
import Timeline from 'primevue/timeline';
import Card from 'primevue/card';
import MultiSelect from 'primevue/multiselect';
import Menu from 'primevue/menu';
import Editor from 'primevue/editor';
import AutoComplete from 'primevue/autocomplete';
import Image from 'primevue/image';
import Divider from 'primevue/divider';
import Password from 'primevue/password';
import BadgeDirective from 'primevue/badgedirective';
import SelectButton from 'primevue/selectbutton';
import RadioButton from 'primevue/radiobutton';
import Panel from 'primevue/panel';
import InlineMessage from 'primevue/inlinemessage';
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';
import ConfirmationService from 'primevue/confirmationservice';
import Paginator from 'primevue/paginator';

import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';

import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import router from '@/router';
import axios from '@/plugins/axios';

import { locale_pt_br } from './commons/utils';

const app = createApp(App);

if (import.meta.env.VITE_APP_ENV != 'local') {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_ENV,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        tracePropagationTargets: [import.meta.env.VITE_SENTRY_TARGET_URL],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });    
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

app.use(PrimeVue, { 
    ripple: true,
    locale: locale_pt_br(),
});
app.use(ToastService);
app.use(pinia);
app.use(router);
app.use(axios, {
    baseURL: import.meta.env.VITE_API_URL,
    token: null,
})
app.use(ConfirmationService);
app.use(VueTheMask);

// Componentes do sistema
import ReturnTrail from './components/elementos/ReturnTrail/ReturnTrail.vue';
import CardMobile from './components/elementos/CardMobile/CardMobile.vue';
import LeitorPdf from './components/LeitorPdf.vue';
import TagStatusSolicitacoes from './components/elementos/TagStatus/TagStatusSolicitacoes.vue';
import TagStatusPagamentos from './components/elementos/TagStatus/TagStatusPagamentos.vue';
import TabelaHistorico from './components/elementos/Tabelas/TabelaHistorico.vue';

// Componentes do sistema
app.component('TabelaHistorico', TabelaHistorico);
app.component('ReturnTrail', ReturnTrail);
app.component('LeitorPdf', LeitorPdf);
app.component('CardMobile', CardMobile);
app.component('TagStatusSolicitacoes', TagStatusSolicitacoes);
app.component('TagStatusPagamentos', TagStatusPagamentos);
// Componentes do PrimeVue
app.component('Paginator', Paginator);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Skeleton', Skeleton);
app.component('PanelMenu', PanelMenu);
app.component('ConfirmPopup', ConfirmPopup);
app.component('InlineMessage', InlineMessage);
app.component('Panel', Panel);
app.component('Fieldset', Fieldset);
app.component('InputText', InputText);
app.component('InputMask', InputMask);
app.component('Checkbox', Checkbox);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Avatar', Avatar);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('Textarea', Textarea);
app.component('InputSwitch', InputSwitch);
app.component('FileUpload', FileUpload);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('Dropdown', Dropdown);
app.component('Tag', Tag);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Calendar', Calendar);
app.component('Chip', Chip);
app.component('InputNumber', InputNumber);
app.component('OverlayPanel', OverlayPanel);
app.component('Message', Message);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Timeline', Timeline);
app.component('Card', Card);
app.component('MultiSelect', MultiSelect);
app.component('Menu', Menu);
app.component('Editor', Editor);
app.component('AutoComplete', AutoComplete);
app.component('Image', Image)
app.component('Divider', Divider)
app.component('Password', Password)
app.component('SelectButton', SelectButton)
app.component('RadioButton', RadioButton)
app.component('ProgressBar', ProgressBar)
app.directive('ripple', ripple);
app.directive('styleclass', styleclass);
app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);



app.mount('#app');